import { Box, Popover, PopoverContent, PopoverTrigger, Portal, type As } from '@chakra-ui/react';
import clsx from 'clsx';
import { forwardRef, type ReactNode } from 'react';

import type { PolymorphicProps, PolymorphicRef } from '@endaoment-frontend/types';

import styles from './HoverPopover.module.scss';

type HoverPopoverProps<Tag extends As> = PolymorphicProps<
  'button',
  Tag,
  {
    triggerText?: Array<ReactNode> | ReactNode;
    children: Array<ReactNode> | ReactNode;
    popoverClassName?: string;
  }
>;

const HoverPopoverWithRef = <Tag extends As>(
  { triggerText, popoverClassName, children, as = 'button', ...props }: HoverPopoverProps<Tag>,
  ref: PolymorphicRef<Tag>,
) => {
  return (
    <Popover trigger='hover' placement='top' variant='alwaysOnTop' isLazy>
      <PopoverTrigger>
        <Box as={as ?? 'button'} ref={ref} {...props}>
          {triggerText}
        </Box>
      </PopoverTrigger>
      <Portal appendToParentPortal>
        <PopoverContent className={clsx(styles['hover-content'], popoverClassName)}>{children}</PopoverContent>
      </Portal>
    </Popover>
  );
};

export const HoverPopover = forwardRef(HoverPopoverWithRef);
