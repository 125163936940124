import { useId } from 'react';

import type { IconProps } from './Icon.types';

export const LogoIcon = ({ width = 35, height, color, strokeColor, strokeWidth = 0, ...props }: IconProps) => {
  const gradientId0 = useId();
  const gradientId1 = useId();
  const gradientId2 = useId();
  const gradientId3 = useId();
  const gradientId4 = useId();
  const gradientId5 = useId();
  const gradientId6 = useId();
  const gradientId7 = useId();
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M7.00166 8.53998C8.37721 8.17141 9.19352 6.75753 8.82494 5.38199C8.45637 4.00645 7.04247 3.19015 5.66692 3.55872C4.29136 3.92729 3.47505 5.34118 3.84363 6.71672C4.2122 8.09225 5.6261 8.90856 7.00166 8.53998Z'
        fill={color ?? `url(#${gradientId0})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.24683 1.00104L12.9828 0L15.0895 7.19373L14.1346 10.5518C13.7113 12.2417 12.3918 13.5612 10.7019 13.9845L7.11373 14.8833L0 12.9829L1.00106 9.24691L12.265 12.2651L9.24683 1.00104Z'
        fill={color ?? `url(#${gradientId1})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d='M7.11373 14.8833L0.000114556 17.0169L1.00117 20.7529L1.00156 20.7528L1.00159 20.7529L12.2649 17.7349L9.247 28.9977L9.24704 28.9977L9.24671 28.999L12.9827 30L12.983 29.9988L14.9744 22.5671L14.1346 19.2149C13.7113 17.525 12.3918 16.2055 10.7019 15.7822L7.11373 14.8833Z'
        fill={color ?? `url(#${gradientId2})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d='M7.00166 26.0812C8.37721 25.7126 9.19352 24.2987 8.82495 22.9232C8.45637 21.5477 7.04247 20.7314 5.66692 21.0999C4.29136 21.4685 3.47505 22.8824 3.84363 24.2579C4.2122 25.6335 5.6261 26.4498 7.00166 26.0812Z'
        fill={color ?? `url(#${gradientId3})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d='M22.4796 15.002L19.3651 15.7822C17.6752 16.2055 16.3556 17.525 15.9323 19.2149L14.9744 22.5671L17.0169 29.9991L20.7529 28.9981L17.7349 17.7349L28.9984 20.7529L29.9995 17.0169L22.4796 15.002Z'
        fill={color ?? `url(#${gradientId4})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d='M24.354 26.0812C25.7295 25.7126 26.5459 24.2987 26.1773 22.9232C25.8087 21.5477 24.3948 20.7314 23.0192 21.0999C21.6437 21.4685 20.8274 22.8824 21.196 24.2579C21.5645 25.6335 22.9784 26.4498 24.354 26.0812Z'
        fill={color ?? `url(#${gradientId5})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d='M15.0895 7.19373L15.9244 10.6236C16.3502 12.3728 17.7336 13.7276 19.4913 14.1168L22.4796 15.002L30 12.9829L28.9989 9.2469L17.7348 12.2651L20.7528 1.00192L17.0168 0.000870988L15.0895 7.19373Z'
        fill={color ?? `url(#${gradientId6})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d='M26.1773 5.38199C26.5459 6.75753 25.7295 8.17141 24.354 8.53998C22.9784 8.90856 21.5645 8.09225 21.196 6.71672C20.8274 5.34118 21.6437 3.92729 23.0192 3.55872C24.3948 3.19014 25.8087 4.00645 26.1773 5.38199Z'
        fill={color ?? `url(#${gradientId7})`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <defs>
        <linearGradient id={gradientId0} x1='15' y1='0' x2='15' y2='15' gradientUnits='userSpaceOnUse'>
          <stop offset='0.025' stopColor='#FA5908' />
          <stop offset='1' stopColor='#FFAD65' />
        </linearGradient>
        <linearGradient id={gradientId1} x1='15' y1='0' x2='15' y2='15' gradientUnits='userSpaceOnUse'>
          <stop offset='0.025' stopColor='#FA5908' />
          <stop offset='1' stopColor='#FFAD65' />
        </linearGradient>
        <linearGradient id={gradientId2} x1='15' y1='15' x2='15' y2='30' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#EA4705' />
          <stop offset='1' stopColor='#FF6CB3' />
        </linearGradient>
        <linearGradient id={gradientId3} x1='15' y1='15' x2='15' y2='30' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#EA4705' />
          <stop offset='1' stopColor='#FF6CB3' />
        </linearGradient>
        <linearGradient id={gradientId4} x1='15' y1='15' x2='15' y2='30' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#8478E0' />
          <stop offset='1' stopColor='#F78AFF' />
        </linearGradient>
        <linearGradient id={gradientId5} x1='15' y1='15' x2='15' y2='30' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#8478E0' />
          <stop offset='1' stopColor='#F78AFF' />
        </linearGradient>
        <linearGradient id={gradientId6} x1='15' y1='0' x2='15' y2='15' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#8478E0' />
          <stop offset='1' stopColor='#53ACDE' />
        </linearGradient>
        <linearGradient id={gradientId7} x1='15' y1='0' x2='15' y2='15' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#8478E0' />
          <stop offset='1' stopColor='#53ACDE' />
        </linearGradient>
      </defs>
    </svg>
  );
};
