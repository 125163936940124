import type { As } from '@chakra-ui/react';
import { Box, Tooltip as ChakraTooltip } from '@chakra-ui/react';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import type { PolymorphicProps, PolymorphicRef } from '@endaoment-frontend/types';

import styles from './Tooltip.module.scss';

type Placement = 'bottom' | 'left' | 'right' | 'top';

type TooltipProps<Tag extends As> = PolymorphicProps<
  'span',
  Tag,
  {
    content: ReactNode;
    placement?: Placement;
    children?: ReactNode;
    className?: string;
    innerClassName?: string;
    isDefinition?: boolean;
    __devIsOpen?: boolean;
  }
>;

const TooltipWithRef = <Tag extends As>(
  {
    content,
    placement = 'top',
    children,
    as = 'span',
    className,
    innerClassName,
    isDefinition = false,
    __devIsOpen,
    ...props
  }: TooltipProps<Tag>,
  ref: PolymorphicRef<Tag>,
) => {
  return (
    <ChakraTooltip
      ref={ref}
      isOpen={typeof __devIsOpen === 'boolean' ? __devIsOpen : undefined}
      label={<div className={innerClassName}>{content}</div>}
      placement={placement}
      display='inline-block'
      shouldWrapChildren={false}
      isDisabled={!content}
      closeOnClick={false}
      hasArrow={isDefinition}>
      <Box as={as} {...props} className={clsx(className, styles['content'])}>
        {children}
        {!!isDefinition && (
          <svg viewBox='0 0 300 100' preserveAspectRatio='none'>
            <path d='M0 100H300' vectorEffect='non-scaling-stroke' />
          </svg>
        )}
      </Box>
    </ChakraTooltip>
  );
};
export const Tooltip = forwardRef(TooltipWithRef);
