import { TrackingHeadScript } from '@phntms/next-gtm';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import { config, featureFlags } from '@endaoment-frontend/config';
import { convertDehydratedStringToState, defaultQueryClient } from '@endaoment-frontend/data-fetching';
import { StylesProvider } from '@endaoment-frontend/ui/shared';
import { HeapLoader, IntercomLoader, TypeformModalProvider } from '@endaoment-frontend/ui/smart';

import 'swiper/css';
import 'swiper/css/pagination';
import '../styles/globals.scss';

export type MarketingAppProps = AppProps<{
  /**
   * Dehydrated state from server-side rendering
   */
  dehydratedState?: string;
}>;

const App = ({ Component, pageProps }: MarketingAppProps) => {
  return (
    <>
      {/* Uses the NEXT_PUBLIC_GA_MEASUREMENT_ID env variable */}
      <TrackingHeadScript id={featureFlags.gaMeasurementId} isGTM />
      <Head>
        <title>Endaoment</title>
        <link rel='icon' href='/images/favicon.ico' sizes='any' />
        <link rel='icon' href='/images/favicon.svg' type='image/svg+xml' />
        <link rel='apple-touch-icon' href='/images/apple-touch-icon.png' />
        <meta name='theme-color' content='#E4EBF4' />

        <meta name='viewport' content='width=device-width, initial-scale=1' />

        {/* Disable indexing in non-production environments */}
        {config.environmentName !== 'production' && <meta name='robots' content='noindex' />}
      </Head>
      <StylesProvider>
        <QueryClientProvider client={defaultQueryClient}>
          <HydrationBoundary state={convertDehydratedStringToState(pageProps.dehydratedState)} />
          <IntercomLoader />
          <HeapLoader />

          <Component {...pageProps} />

          <TypeformModalProvider />

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </StylesProvider>
    </>
  );
};

export default App;
