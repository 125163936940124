import type { Digit, Letter, OrgDetails, TransactionStatus } from '@endaoment-frontend/types';

export const getNTEEMajorCode = (org?: Pick<OrgDetails, 'nteeCode'>): Letter => {
  if (!org) return 'Z';
  try {
    return org.nteeCode.trim()[0].toUpperCase() as Letter;
  } catch {
    return 'Z';
  }
};

export const getNTEECode = (org: Pick<OrgDetails, 'nteeCode'>): `${Letter}${Digit}${Digit}` => {
  const major = getNTEEMajorCode(org);

  // If unknown, return 'Z00'
  if (major === 'Z') return 'Z00';

  const minor = org.nteeCode.trim().slice(1, 3) as `${Digit}${Digit}`;

  return `${major}${minor}`;
};

export const getNTEECategory = (org: Pick<OrgDetails, 'nteeCode'>): string => {
  const major = getNTEEMajorCode(org);

  // If unknown, return 'Z00'
  if (major === 'Z') return 'Unknown Type';

  const category = org.nteeCode.trim().slice(5);

  return category;
};

export const getParsedUrl = (url?: string): string | null => {
  if (!url) return null;

  let parsedUrl: URL;
  try {
    parsedUrl = new URL(url);

    return parsedUrl.href.replace(/\/$/, '');
  } catch {
    return `http://${url}`;
  }
};

/**
 * Returns array of string values from obj
 * @param obj to select string values from
 * @returns an array of values from obj that for all the keys in ```T``` that are of type ```string```
 */
export const selectStringsFromObject = <T>(obj: T): Array<string> => {
  return obj === null || typeof obj === 'undefined'
    ? []
    : Object.values(obj).map(v => (typeof v === 'string' ? v : ''));
};

/**
 * Returns an array of values from obj that the keys for are of type ```string```
 * @param array the origin array to filter
 * @param filterValue the string to use as a filter
 * @param [stringValueArrayForFilter] an optional index-mapped array of strings to filter array by
 * @returns the filtered array
 */
export const genericStringFilter = <T>(
  array: Array<T>,
  filterValue: string,
  stringValueArrayForFilter?: Array<Array<string>>,
): Array<T> => {
  const fArray = stringValueArrayForFilter ?? array.map(item => selectStringsFromObject(item));

  return array.filter((_t, index) =>
    fArray[index].reduce<boolean>(
      (prev, current) => prev || current?.toLowerCase().includes(filterValue.toLowerCase()),
      false,
    ),
  );
};

export const stringToColor = (stringInput = ''): string => {
  const stringUniqueHash = stringInput.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);

  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
};

export const arraySliceNextN = <T>(orig: Array<T>, index: number, length: number): Array<T> => {
  const arr: Array<T> = [];
  let curr = index + 1;
  for (let i = 0; i < length; i++) {
    if (curr > orig.length - 1) {
      if (length > orig.length) break;
      curr = 0;
    }
    arr.push(orig[curr]);
    curr++;
  }
  return arr;
};

export const isUnsentStatus = (status: TransactionStatus): status is 'none' | 'rejected' =>
  ['none', 'rejected'].includes(status);

export const isViewableBlockchainStatus = (status: TransactionStatus): status is 'error' | 'pending' | 'success' =>
  status === 'error' || status === 'pending' || status === 'success';

export const chunkArray = <T>(input: Array<T>, size: number): Array<Array<T>> =>
  input.reduce(
    (arr: Array<Array<T>>, item, idx) =>
      idx % size === 0 ? [...arr, [item]] : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]],
    [],
  );

export const delay = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

export const arrToSorted = <T>(arr: Array<T>, compareFn?: (a: T, b: T) => number): Array<T> => {
  if (arr.length === 0) return arr;

  const copy = [...arr];
  copy.sort(compareFn);
  return copy;
};
