import clsx from 'clsx';
import { motion, type MotionStyle } from 'framer-motion';
import memoize from 'micro-memoize';

import styles from './MultiProgressBar.module.scss';

const normalizeParts = memoize(
  <Part extends { progress: bigint | number }>(parts: Array<Part>): Array<Part & { offset: number }> => {
    const totalValue = parts.reduce((acc, part) => acc + Number(part.progress), 0);
    const normalizedParts: Array<Part & { offset: number }> = [];
    let counter = 0;
    for (const part of parts) {
      const normalizedProgress = (Number(part.progress) / totalValue) * 100;
      const normalizedOffset = (counter / totalValue) * 100;
      normalizedParts.push({ ...part, progress: normalizedProgress, offset: normalizedOffset });
      counter += Number(part.progress);
    }

    return normalizedParts;
  },
);

export const MultiProgressBar = ({
  parts,
  breakdown,
  className,
}: {
  parts: Array<{
    progress: bigint | number;
    color?: string;
    className?: string;
    secondColor?: string;
    style?: MotionStyle;
  }>;
  breakdown?: boolean;
  className?: string;
}) => {
  const normalizedParts = normalizeParts(parts);
  return (
    <div className={clsx(styles['progress'], breakdown && styles['progress--breakdown'], className)}>
      {normalizedParts.map(
        (part, i) =>
          part.progress > 0 && (
            <motion.div
              key={`${part.progress}${i}`}
              className={clsx(styles['progress-part'], part.className)}
              data-index={i}
              style={{
                ...part.style,
                left: `${part.offset}%`,
                width: `${part.progress}%`,
                background: part.secondColor
                  ? `repeating-linear-gradient( 135deg, ${part.color}, ${part.color} 3px, ${part.secondColor} 3px, ${part.secondColor} 6px )`
                  : part.color,
              }}
            />
          ),
      )}
    </div>
  );
};
